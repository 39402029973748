import React from 'react'
import { useRive } from '@rive-app/react-canvas';

const RiveAnimation = (props) => {

    const { rive, RiveComponent } = useRive({
        src: props.src,
        stateMachines: props.stateMachineName,
        autoplay: true,
    })

    return (
        <RiveComponent className={props.customClass}/>
    )
}

export default RiveAnimation;