import React, { useState, useEffect } from 'react';

import Header from '../components/Header';
import translations from '../translations';

const Help = () => {
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        document.body.classList.add('helpbody');
        return () => {
            document.body.classList.remove('helpbody');
        };
    }, []);

    const switchTab = (index) => {
        setActiveTab(index)
    }


    return (
        <div>
            <Header />

            <div className='helpPage'>
                <div className='container'>
                    <div className="title">{localStorage.getItem('selectedLanguage') == 'ar' ? translations.help.helpAr : translations.help.help}</div>
                </div>

                <div className='navTabs'>
                    <div className='container'>
                        <ul className="nav">
                            <li className={`navItem ${activeTab === 0 ? 'active' : ''}`} onClick={() => switchTab(0)}>{localStorage.getItem('selectedLanguage') == 'ar' ? translations.help.controlsAr : translations.help.controls}</li>
                            <li className={`navItem ${activeTab === 1 ? 'active' : ''}`} onClick={() => switchTab(1)}>{localStorage.getItem('selectedLanguage') == 'ar' ? translations.help.searchAr : translations.help.search}</li>
                            <li className={`navItem ${activeTab === 2 ? 'active' : ''}`} onClick={() => switchTab(2)}>{localStorage.getItem('selectedLanguage') == 'ar' ? translations.help.sidebarAr : translations.help.sidebar}</li>
                        </ul>
                    </div>

                </div>

                {activeTab == 0 &&
                    <div className="tabsContent">
                        <div className='container'>
                            <div className='itemsListing'>
                                <div className='listingItem'>
                                    <div className='listingImage'>
                                        <img src="/hotspots.jpg" />
                                    </div>

                                    <div className='listingInfo'>
                                        <div className='listingTitle'>Hotspots</div>
                                        <div className='listingDescription'>
                                            Clicking on the hotspots will take you through to buildings and then into rooms within the city.
                                        </div>
                                    </div>
                                </div>
                                <div className='listingItem'>
                                    <div className='listingImage'>
                                        <img src="/levels.jpg" />
                                    </div>

                                    <div className='listingInfo'>
                                        <div className='listingTitle'>Levels</div>
                                        <div className='listingDescription'>
                                            Clicking on this icon will take you up a level from the one that you are currently on.
                                        </div>
                                    </div>
                                </div>
                                <div className='listingItem'>
                                    <div className='listingImage'>
                                        <img src="/location.jpg" />
                                    </div>

                                    <div className='listingInfo'>
                                        <div className='listingTitle'>Location</div>
                                        <div className='listingDescription'>
                                            Your current location is always shown above the search bar.
                                        </div>
                                    </div>
                                </div>
                                <div className='listingItem'>
                                    <div className='listingImage'>
                                        <img src="/products.jpg" />
                                    </div>

                                    <div className='listingInfo'>
                                        <div className='listingTitle'>Products</div>
                                        <div className='listingDescription'>
                                            Clicking on this icon will open the basket to check the products you selected.
                                        </div>
                                    </div>
                                </div>
                                <div className='listingItem'>
                                    <div className='listingImage'>
                                        <img src="/rooms.jpg" />
                                    </div>

                                    <div className='listingInfo'>
                                        <div className='listingTitle'>Rooms</div>
                                        <div className='listingDescription'>
                                            You can travel from one room to another by clicking the circular buttons at the edge of the screen.
                                        </div>
                                    </div>
                                </div>
                                <div className='listingItem'>
                                    <div className='listingImage'>
                                        <img src="/searchbar.jpg" />
                                    </div>

                                    <div className='listingInfo'>
                                        <div className='listingTitle'>Search Bar</div>
                                        <div className='listingDescription'>
                                            Clicking on this icon will open the search bar to help you find your desired information
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='smallFooter'>
                                {localStorage.getItem('selectedLanguage') == 'ar' ? translations.help.copyrightAr : translations.help.copyright}
                            </div>
                        </div>
                    </div>
                }

                {activeTab == 1 &&
                    <div className="tabsContent">
                        <div className='container'>
                            <div className='itemsListing'>
                                <div className='listingItem'>
                                    <div className='listingImage'>
                                        <img src="/hotspots.jpg" />
                                    </div>

                                    <div className='listingInfo'>
                                        <div className='listingTitle'>Hotspots</div>
                                        <div className='listingDescription'>
                                            Clicking on the hotspots will take you through to buildings and then into rooms within the city.
                                        </div>
                                    </div>
                                </div>
                                <div className='listingItem'>
                                    <div className='listingImage'>
                                        <img src="/levels.jpg" />
                                    </div>

                                    <div className='listingInfo'>
                                        <div className='listingTitle'>Levels</div>
                                        <div className='listingDescription'>
                                            Clicking on this icon will take you up a level from the one that you are currently on.
                                        </div>
                                    </div>
                                </div>
                                <div className='listingItem'>
                                    <div className='listingImage'>
                                        <img src="/location.jpg" />
                                    </div>

                                    <div className='listingInfo'>
                                        <div className='listingTitle'>Location</div>
                                        <div className='listingDescription'>
                                            Your current location is always shown above the search bar.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='smallFooter'>
                                {localStorage.getItem('selectedLanguage') == 'ar' ? translations.help.copyrightAr : translations.help.copyright}
                            </div>
                        </div>
                    </div>
                }

                {activeTab == 2 &&
                    <div className="tabsContent">
                        <div className='container'>
                            <div className='itemsListing'>
                                <div className='listingItem'>
                                    <div className='listingImage'>
                                        <img src="/hotspots.jpg" />
                                    </div>

                                    <div className='listingInfo'>
                                        <div className='listingTitle'>Hotspots</div>
                                        <div className='listingDescription'>
                                            Clicking on the hotspots will take you through to buildings and then into rooms within the city.
                                        </div>
                                    </div>
                                </div>
                                <div className='listingItem'>
                                    <div className='listingImage'>
                                        <img src="/levels.jpg" />
                                    </div>

                                    <div className='listingInfo'>
                                        <div className='listingTitle'>Levels</div>
                                        <div className='listingDescription'>
                                            Clicking on this icon will take you up a level from the one that you are currently on.
                                        </div>
                                    </div>
                                </div>
                                <div className='listingItem'>
                                    <div className='listingImage'>
                                        <img src="/location.jpg" />
                                    </div>

                                    <div className='listingInfo'>
                                        <div className='listingTitle'>Location</div>
                                        <div className='listingDescription'>
                                            Your current location is always shown above the search bar.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='smallFooter'>
                                {localStorage.getItem('selectedLanguage') == 'ar' ? translations.help.copyrightAr : translations.help.copyright}
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>


    );
};

export default Help;