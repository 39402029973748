
import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useParams } from 'react-router-dom';


import Home from './pages/Home';
import Help from './pages/Help';
import NotFound from './pages/NotFound';

function App() {
  const changeFavicon = (link) => {
    let favicon = document.querySelector("link[rel~='icon']");
    if (!favicon) {
      favicon = document.createElement("link");
      favicon.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(favicon);
    }
    favicon.href = link;
  };

  useEffect(() => {
    // Change document title
    // document.title = "New Document Title";
    if (localStorage.getItem('mode_isEcommerce') == "true") {
      document.title = localStorage.getItem('selectedLanguage') == 'en' ? "Woosooq Connect" : "وسوق كونكت";
      changeFavicon('woosooqfavicon.ico');
    } else {
      document.title = localStorage.getItem('selectedLanguage') == 'en' ? "Napco B2B Gate" : "نابكو جايت";
      changeFavicon('napcofavicon.ico');
    }

    // Change favicon



  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/help" exact element={<Help />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
