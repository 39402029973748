import React, { useState } from 'react';
import bcrypt from 'bcryptjs';


const Password = ({ onUnlock }) => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);

    const hashedPassword = '$2y$10$74Klb1U.SGgDyDfINE.6LuFKRmh1qRxPTWfFS9hIbo8et770g63bm';

    const handleSubmit = (event) => {
        event.preventDefault();
        if (bcrypt.compareSync(password, hashedPassword)) {
            onUnlock();
        } else {
            setError(true);
        }
    };

    return (
        <div className="password-container">
            <img src="logoNapco-3-bilingual.png" />

            <form onSubmit={handleSubmit}>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter password"
                    className={error ? 'error' : ''}
                />
                <button type="submit">Unlock</button>
            </form>
        </div>
    );
};

export default Password;
