import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import Header from '../components/Header';

const NotFound = () => {

  useEffect(() => {
    document.body.classList.add('helpbody');
    return () => {
      document.body.classList.remove('helpbody');
    };
  }, []);


  return (
    <div>
      <Header />
      <Link to="/" className='notfound' >
      </Link>
    </div>
  );
};

export default NotFound;
