const translations = {
    header: {
        "languageLabel": "Choose language",
        "languageLabelAr": "اختر اللغة",

        "countryLabel": "Choose country",
        "countryLabelAr": "اختر البلد",
    },

    products: {
        "backTo": "Back to",
        "backToAr": "العودة إلى",

        "backToList": "Back to List",
        "backToListAr": "العودة إلى القائمة",

        "addtoCartBtn": "ADD TO CART",
        "addtoCartBtnAr": "أضف إلى السلة",

        "gotoCartBtn": "Go To Cart",
        "gotoCartBtnAr": "انتقل إلى السلة",

        "continueBrowsing": "Continue Browsing",
        "continueBrowsingAr": "متابعة التصفح",

        "quantityLabel": "Quantity",
        "quantityLabelAr": "الكمية",

        "prodAddedToCart": "Product was added to your Cart",
        "prodAddedToCartAr": "تمت إضافة المنتج إلى سلة التسوق",
    },

    footer: {
        "myCart": "My Cart",
        "myCartAr": "سلة التسوق",

        "cartEmpty": "Your cart is empty. You must add some items before you check out",
        "cartEmptyAr": "سلة التسوق فارغة. يجب عليك إضافة بعض العناصر قبل الدفع",

        "checkoutBtn": "CONTINUE TO CHECKOUT",
        "checkoutBtnAr": "المتابعة إلى الدفع",

        "mapHint": "Drag the map to navigate.",
        "mapHintAr": "اسحب الخريطة للتنقل.",
    },

    search: {
        "searchPlaceholder": "Enter your search",
        "searchPlaceholderAr": "أدخل بحثك",

        "searchResultsLabel": "Results Were Found",
        "searchResultsLabelAr": "تم العثور على نتائج",
    },

    help: {
        "help": "Help",
        "helpAr": "مساعدة",

        "controls": "Controls",
        "controlsAr": "التحكم",

        "search": "Search",
        "searchAr": "بحث",

        "sidebar": "Sidebar",
        "sidebarAr": "الشريط الجانبي",
        
        "copyright": "Copyright © Napco. All rights reserved.",
        "copyrightAr": "حقوق النشر © Napco. كل الحقوق محفوظة."
    }
};


export default translations;
